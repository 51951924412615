import React from "react";
import {Button} from "../../../../Components/ui/Button"; // Assumes you have a Button component
import {Input} from "../../../../Components/ui/Input";
import SafeQuillEditor from "../../../../Components/ui/QuillEditor";

const FAQs = ({formData, setFormData}) => {
    const handleAddFAQ = () => {
        setFormData({
            ...formData, faqs: [...formData.faqs, {question: "", answer: ""}],
        });
    };

    const handleDeleteFAQ = (index) => {
        const updatedFAQs = formData.faqs.filter((_, i) => i !== index);
        setFormData({...formData, faqs: updatedFAQs});
    };

    const handleFAQChange = (index, field, value) => {
        const updatedFAQs = formData.faqs.map((faq, i) => i === index ? {...faq, [field]: value} : faq);
        setFormData({...formData, faqs: updatedFAQs});
    };

    return (<div className="w-full">
        <h2 className="text-lg font-bold mb-4">FAQs</h2>
        {formData.faqs.map((faq, index) => (<div key={index} className="mb-4 border p-4 rounded-lg">
            <div className="mb-2">
                <label htmlFor={`question-${index}`} className="block font-medium">
                    Question
                </label>
                <Input
                    id={`question-${index}`}
                    value={faq.question}
                    onChange={(e) => handleFAQChange(index, "question", e.target.value)}
                    placeholder="Enter question"
                    className="w-full"
                />
            </div>
            <div className="mb-2">
                <label htmlFor={`answer-${index}`} className="block font-medium">
                    Answer
                </label>
                <SafeQuillEditor
                    id={`answer-${index}`}
                    value={faq.answer}
                    onChange={(value) => handleFAQChange(index, "answer", value)}
                    placeholder="Enter answer"
                />
            </div>
            <Button
                variant="destructive"
                type={'button'}
                onClick={() => handleDeleteFAQ(index)}
                className="mt-2 bg-red-600 flex items-center justify-center gap-1"
            >
                <span
                    className="cursor-pointer text-center  shadow-lg material-symbols-rounded  font-light text-md ">delete</span>
                Delete
            </Button>
        </div>))}
        <Button type="button" onClick={handleAddFAQ} className="mt-4 bg-green-600 flex items-center justify-center gap-1">
              <span
                  className="cursor-pointer text-center  shadow-lg material-symbols-rounded  font-light text-md ">add</span>
            Add Question
        </Button>
    </div>);
};

export default FAQs;
