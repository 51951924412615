import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import SidebarIcons from "../PanelTemplate/SidebarIcons";
import PanelSidebarMenu from "../PanelTemplate/PanelSidebarMenu";
import PanelHeaderMenu from "../PanelTemplate/PanelHeaderMenu";
import PageHeader from "../PanelTemplate/PageHeader";
import {hideSidebar} from "../../redux/actions/AdminPanelActions";
import {Navigate, useLocation} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {fetchAPISettingsData, fetchPaymentSettingsData} from "../../redux/actions/SettingsActions";
import {SET_CURRENCY} from "../../redux/Constants/AdminPanelConstants";
import {getNameFromPathname} from "../../utils/UtilityFunctions";

const sidebarIcons = [
    {
    id: 0,
    name: "home",
    label: "Home",
    pageList: ["Dashboard", "Navigation", "Top Bar", "Footer Links", "Image Sliders", "Banners", "Coupons", "Users", "Homepage Manager",]
}, {
    id: 4,
    name: "content_paste",
    label: "Content",
    pageList: ["Add Page", "All Pages", "Drafted Pages", "Page Editor", "Media Manager", "Items Organizer", "Item Groups", "Features", "Team Members", "Custom Design", "Rich Content Editors", "Custom Code"]
}, {
    id: 1,
    name: "shopping_cart",
    label: "Orders",
    pageList: ["All Orders", "Pending Orders", "Delivered Orders", "Cancelled Orders", "Invoices", "Feedbacks", "Checkout Form"]
}, {
    id: 2,
    name: "inventory_2",
    label: "Products",
    pageList: ["All Products", "Add Product", "Variations", "Upload Bulk Products", "Migrate From Shopify", "Migrate From Wordpress", "Drafted Products",]
}, {
    id: 3,
    name: "folder_open",
    label: "Categories",
    pageList: ["All Categories", "Add Category", "Bulk Category Upload", "Drafted Categories",]
}, {
    id: 8,
    name: "apps",
    label: "Collections",
    pageList: ["All Collections", "Add Collection", "Bulk Collection Upload", "Drafted Collections",]
}, {
    id: 7,
    name: "reviews",
    label: "Reviews",
    pageList: ["Pending Reviews", "Approved Reviews", "Add Review", "Upload Bulk Reviews"]
},

    {
        id: 9,
        name: "local_shipping",
        label: "Couriers",
        pageList: ["Shipping Settings", "Sonic Trax", "TCS", "Leopards",]
    }, {
        id: 10, name: "payments", label: "Payments", pageList: ["Payment Settings", "Currency Settings", "Stripe"]
    }, {
        id: 5,
        name: "settings",
        label: "Settings",
        pageList: ["General Settings", "Product Settings", "Category Settings", "Collection Settings", "Contact Settings", "Social Settings", "Email Settings", "Visual Settings", "Account Settings", "API Settings"]
    },

    {
        id: 6,
        name: "delete",
        label: "Trash",
        pageList: ["Deleted Categories", "Deleted Collections", "Deleted Products", "Deleted Orders", "Deleted Pages"]
    }]

const AdminPanelTemplate = ({PageContent}) => {
    let {paymentSettings, success} = useSelector((state) => state.paymentSettingsData);
    const {currencies} = paymentSettings
    useEffect(() => {
        if (success) {
            dispatch({
                type: SET_CURRENCY, payload: currencies.find(x => x.is_base).symbol
            })
        }
    }, [success])
    useEffect(() => {
        dispatch(fetchPaymentSettingsData())
        dispatch(fetchAPISettingsData())
    }, [])
    const dispatch = useDispatch()
    const contentClicked = () => {
        dispatch(hideSidebar())
    }
    const location = useLocation()
    const pathname = location.pathname
    const sidebar = useSelector((state) => state.sidebar);
    const {user} = useSelector((state) => state.userData);
    const auth = useSelector((state) => state.auth);
    const logoutUserData = useSelector((state) => state.logoutUser);
    const isMobile = useMediaQuery({query: '(max-width: 900px)'});
    const generalSettingsData = useSelector((state) => state.generalSettingsData);
    const {generalSettings} = generalSettingsData;
    useEffect(() => {
        if (generalSettingsData.success) {
            const selectedPage = getNameFromPathname(pathname, true)
            document.title = `${selectedPage} | ${generalSettings.app_name} | Panel`;
        }
    }, [generalSettingsData.success, pathname]);
    if (!auth || logoutUserData.success) {
        return <Navigate to="/"/>
    }

    const isDynamicPageContent = (pathname) => {
        const dynamicPaths = ["orders", "reviews", "l-products", "d-products", "l-categories", "d-categories", "invoices", "-pages", "l-collections", "d-collections"];
        return dynamicPaths.some(path => pathname.includes(path));
    };
    return (Object.keys(user).length !== 0 && <>
            <div className="flex font-poppins transition-all">
                {/*Sidebar Section*/}
                <div
                    className={`fixed w-[350px] z-40 ${!sidebar ? "-ml-[80%]" : ""} transition-all duration-500`}>
                    <div className="flex">
                        <SidebarIcons icons={sidebarIcons}/>
                        <PanelSidebarMenu icons={sidebarIcons}/>
                    </div>
                </div>
                {/*Main Section*/}

                <div
                    className={`w-full flex flex-col`}>
                    <PanelHeaderMenu/>
                    <PageHeader icons={sidebarIcons}/>

                    {/*Page Content*/}
                    {(sidebar) && <div onClick={contentClicked}
                                       className="fixed min-h-screen inset-0 bg-black z-30 opacity-50"></div>}
                    <div className="flex flex-col bg-gray-100 gap-4 p-2 pb-5">
                        {isDynamicPageContent(location.pathname) ? PageContent : <PageContent/>}
                    </div>
                </div>
            </div>
        </>)
}
export default AdminPanelTemplate