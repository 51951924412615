import React from "react";
import RichTextEditor from "../../../../Components/RichTextEditor";
import {Card, CardTitle} from "../../../../Components/ui/Card";
import {Input} from "../../../../Components/ui/Input";
import {Switch} from "../../../../Components/ui/Switch";
import FAQs from "./FAQs";

const ProductDetailsForm = ({formData, setFormData,}) => {
    return (<React.Fragment>
        <Card>
            <CardTitle>Product Details</CardTitle>
            <div className="w-full">
                <label htmlFor="long_description">Long Description</label>
                <RichTextEditor id="long_description" value={formData.long_description}
                                onChange={(value) => setFormData({...formData, long_description: value})}/>
            </div>
            <FAQs formData={formData} setFormData={setFormData}/>
            <Input type="text" id="keywords" value={formData.keywords} required={true}
                   onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                   label="Key Words"/>
            <Switch label="Free Delivery" checked={formData.free_delivery === true}
                    onChange={(e) => setFormData({...formData, free_delivery: e.target.checked})}/>
            <Switch checked={formData.show_stock === true} label="Show Stock"
                    onChange={(e) => setFormData({...formData, show_stock: e.target.checked})}/>
        </Card>
    </React.Fragment>)
}
export default ProductDetailsForm