import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Button} from "../../../Components/ui/Button";
import ProductDetailsForm from "./Components/ProductDetailsForm";
import {useParams} from "react-router-dom";
import ProductInformationForm from "./Components/ProductInformationForm";
import {fetchVariations, fetchVariationTypes} from "../../../redux/actions/VariationActions";
import {fetchCategoryList, fetchCollectionList} from "../../../redux/actions/CategoryActions";
import AddMultipleObjects from "../../../Components/ui/AddMultipleObjects";
import {Card} from "../../../Components/ui/Card";
import BoughtTogetherProducts from "./Components/BoughtTogetherProducts";
import VariationsAndTypes from "./Components/VariationsAndTypes";
import UploadProductImages from "./Components/UploadProductImages";
import {showAlert} from "../../../utils/providers/AlertProvider";
import {fetchProductDetails} from "../../../redux/actions/ProductActions";

const ProductPage = () => {
    const {slug} = useParams()
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchVariations())
        dispatch(fetchVariationTypes())
        dispatch(fetchCategoryList())
        dispatch(fetchCollectionList())
        if (slug) dispatch(fetchProductDetails(slug))
    }, []);

    // Data from API
    const {productDetails, success} = useSelector((state) => state.productDetails);
    const {image_variations, product_details, product, variations_with_types} = productDetails

    // States
    const [variationTypesState, setVariationTypesState] = useState([])
    const [variationsState, setVariationsState] = useState([])

    const [mainImage, setMainImage] = useState({})
    const [images, setImages] = useState([])
    const [imageVariations, setImageVariations] = useState([])

    const [selectedCollections, setSelectedCollections] = useState([])

    const [boughtTogetherProducts, setBoughtTogetherProducts] = useState([])

    const [loading, setLoading] = useState(false);

    const {collections} = useSelector((state) => state.collectionList);

    const productInitialState = {
        name: "",
        SKU: generateRandomString(8).toUpperCase(),
        keywords: "",
        short_description: "",
        long_description: "",
        faqs: [],
        stock: 0,
        category_id: 0,
        price: 0,
        discount_rate: 0,
        sold_times: 0,
        show_stock: false,
        free_delivery: false,
    }

    const [formData, setFormData] = useState(productInitialState)

    useEffect(() => {
        if (slug && success) {
            setFormData({
                ...product_details, ...product,
                image: product.image.slice(7),
                long_description: product_details.description,
                short_description: product.description,
            })
            const variations = variations_with_types.reduce((acc, item) => {
                const variationsWithId = item.variations.map(variation => ({
                    ...variation, variation_type_id: item.variation_type_id
                }));
                return acc.concat(variationsWithId);
            }, []);
            setVariationsState(variations)
            setVariationTypesState(variations_with_types.map(item => ({
                id: item.variation_type_id || item.id, // This handles the case where `variation_type_id` might not be present
                variation_type: item.variation_type
            })))
            setImageVariations(image_variations)
            setBoughtTogetherProducts(product.bought_together)
            setSelectedCollections(collections.reduce((acc, item) => {
                // Check if the item.id is included in product.collections
                if (product.collections.some(x => x === item.id)) {
                    acc.push({
                        label: item.name, value: item.id
                    });
                }
                return acc;
            }, []));
        }
    }, [success, slug])

    const handleProductSubmission = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (formData.category_id === 0) {
            showAlert("error", "Please select a category");
            setLoading(false);
            return;
        }
        formData.variation_types = variationTypesState;
        formData.variations = variationsState;
        formData.bought_together = boughtTogetherProducts.map(product => product.id);
        formData.collection_ids = selectedCollections.map(x => x.value);
        formData.images = images
        formData.image = mainImage
        try {
            const url = slug ? `/admin/products/update/${slug}` : "/admin/products/update";
            const {data} = await axios.post(url, formData);
            console.log(data)
            showAlert("success", slug ? "Product Updated Successfully!" : "Product Added Successfully");

            if (!slug) {
                setFormData(productInitialState);
            }
        } catch (e) {
            console.log(e)
            showAlert("error", e.response && e.response.data.detail ? e.response.data.detail : e.message);
        } finally {
            setLoading(false);
        }
    };

    return (<form onSubmit={handleProductSubmission}>
        <ProductInformationForm setFormData={setFormData} formData={formData}/>
        <Card>
            <div className="w-full flex flex-col gap-2">
                <div>Add to Collection</div>
                <AddMultipleObjects objectsArray={collections} setSelectedObjects={setSelectedCollections}
                                    selectedObjects={selectedCollections} showAlert={showAlert}/>
            </div>
            <VariationsAndTypes setVariationsState={setVariationsState} setVariationTypesState={setVariationTypesState}
                                formData={formData} setFormData={setFormData}
                                variationTypesState={variationTypesState} variationsState={variationsState}/>
            <UploadProductImages images={images} mainImage={mainImage} setMainImage={setMainImage} setImages={setImages}
                                 imageVariations={imageVariations} setImageVariations={setImageVariations}
                                 product={product}
            />
            <BoughtTogetherProducts products={boughtTogetherProducts} setProducts={setBoughtTogetherProducts}/>
        </Card>
        <ProductDetailsForm setFormData={setFormData} formData={formData}/>
        <Button type="submit">{!loading ? slug ? "Update Product" : "Add Product" : "Loading...."}</Button>
    </form>)
}
const generateRandomString = (length) => {
    return Math.random().toString(36).substring(2, length + 2);
};
export default ProductPage