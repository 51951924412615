import React, {useCallback, useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import {showAlert} from "../../utils/providers/AlertProvider";


const SafeQuillEditor = ({
                             value, onChange, placeholder = "Write something...",
                         }) => {
    const quillRef = useRef(null); // Ref for Quill instance
    const [editorValue, setEditorValue] = useState(value); // Local state

    // Sync editor state with external `value`
    useEffect(() => {
        if (value !== editorValue) {
            setEditorValue(value);
        }
    }, [value, editorValue]);

    // Handle content changes
    const handleChange = useCallback((content) => {
        const sanitizedContent = DOMPurify.sanitize(content);
        if (sanitizedContent !== editorValue) {
            setEditorValue((prev) => {
                if (prev === sanitizedContent) return prev; // Prevent unnecessary re-renders
                return sanitizedContent;
            });
            onChange(sanitizedContent); // Call parent onChange
        }
    }, [onChange]);

    // Custom image handler to prevent focus issues
    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files?.[0];
            if (file) {
                if (file.size > 500 * 1024) {
                    showAlert("error", "Image size exceeds 500KB. Please upload a smaller image.");
                    return;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    const quill = quillRef.current?.getEditor();
                    const range = quill?.getSelection(true);
                    if (range) {
                        quill.insertEmbed(range.index, "image", reader.result)
                        quill.setSelection(range.index + 1); // Keep cursor after image
                        setTimeout(() => quillRef.current?.focus(), 0); // Restore focus safely
                    }
                };
                reader.readAsDataURL(file);
            }
        };
    }, []);

    // Define modules with custom toolbar handlers
    const modules = {
        toolbar: {
            container: [[{header: [1, 2, 3, false]}, {font: []}], [{list: "ordered"}, {list: "bullet"}], ["bold", "italic", "underline", "strike"], [{script: "sub"}, {script: "super"}], ["image"], [{align: []}], ["clean"],],
            handlers: {
                image: imageHandler,
            },
        },
    };

    return (<div className="h-52 mt-2 mb-12">
        <ReactQuill
            ref={quillRef}
            value={editorValue}
            onChange={handleChange}
            placeholder={placeholder}
            modules={modules}
            className="h-52 mt-2"
        />
    </div>);
};

export default SafeQuillEditor;
